import DropdownButton from 'app/custom-data-handler/components/buttons/dropdown-button/DropdownButton';
import { TrackerContext } from 'app/custom-data-handler/data/defaultData';
import { AppIcon, continueTrackerIcon } from 'app/shell/icons';
import React from 'react';
import './StopOptionsButton.scss';

function StopOptionsButton() {
  return (
    <TrackerContext.Consumer>
      {(context) => (
        <div className="buttonscontainer" title="Stop Tracker Option Buttons">
          <div className="stopOptionButtons">
            <button
              type="button"
              className="sessionoption continue"
              onClick={context.stopButtonClick}
              value="CONTINUE"
            >
              <span className="icon">
                <AppIcon icon={continueTrackerIcon} />
              </span>
              <span className="text">Continue Session</span>
            </button>
            <DropdownButton />
          </div>
        </div>
      )}
    </TrackerContext.Consumer>
  );
}

export default StopOptionsButton;
