import NotificationTexts from 'app/custom-data-handler/data/notificationText';
import { notify } from 'app/custom-data-handler/functions';
import { NewItem, TrackerObjectType } from 'app/custom-data-handler/types';
import axios, { AxiosInstance } from 'axios';

export default class AjaxCall {
  trackerRequest: AxiosInstance;
  screenshotRequest: AxiosInstance;
  manifestRequest: AxiosInstance;
  expire: number;
  constructor(workerId: number, token: string, expire: number) {
    this.expire = expire;
    this.trackerRequest = axios.create({
      baseURL: `${$AJAX_CALL_URL}/wp-json/ero-destiny-tracker/v1/`,
      responseType: 'json',
      data: {
        workerId,
        token,
      },
    });
    this.screenshotRequest = axios.create({
      baseURL: `${$SCREENSHOT_AJAX_URL}/GS/?action=screenshot`,
      responseType: 'json',
    });

    this.manifestRequest = axios.create({
      baseURL: `${$SCREENSHOT_AJAX_URL}/GS/dim_manifest/index.php`,
      responseType: 'json',
    });
  }

  sendActiveRewards = (items: NewItem[], sessionId: number) => {
    this.requestPostGS('active', {
      sessionId,
      items,
    });
  };

  getGSSettings = async () => {
    const response = await this.requestPostGS('getsettings');
    return response === null || response.error ? false : response;
  };

  getSessionsList = async () => {
    const response = await this.requestPostGS('list');
    return response === null || response.error ? false : response;
  };

  startSession = async (
    screenshotId: number,
    screenshotPath: string,
    selectedOrder: TrackerObjectType
  ) => {
    const response = await this.requestPostGS('start', {
      screenshotId,
      screenshotPath,
      orderId: selectedOrder.orderId,
      orderItemId: selectedOrder.orderItemId,
      sessionId: selectedOrder.id,
    });
    return response !== null && !response.error;
  };

  stopTracker = async (requestData: any, selectedOrder: TrackerObjectType) => {
    const response = await this.requestPostGS('stop', {
      orderId: selectedOrder.orderId,
      orderItemId: selectedOrder.orderItemId,
      sessionId: selectedOrder.id,
      platform: selectedOrder.platform,
      ...requestData,
    });
    return response !== null && !response.error;
  };

  resetRemovedSession = async (selectedOrder: TrackerObjectType) => {
    const response = await this.requestPostGS('restart', {
      id: selectedOrder.id,
      orderId: selectedOrder.orderId,
    });
    return response !== null && !response.error;
  };

  saveScreenshotPath = async (
    screenshotId: number,
    screenshotPath: string,
    selectedOrder: TrackerObjectType
  ) => {
    const response = await this.requestPostGS('screenshot', {
      screenshotId,
      screenshotPath,
      orderId: selectedOrder.orderId,
      orderItemId: selectedOrder.orderItemId,
      sessionId: selectedOrder.id,
    });
    return response === null || response.error ? false : response;
  };

  requestGetGS = async (path: string) => {
    if (this.isTokenExpired()) {
      notify(NotificationTexts.invalidToken);
      return;
    }
    try {
      const params = new URLSearchParams({ ...this.trackerRequest.defaults.data });
      if (path.includes('?')) {
        path = path.replace(/\/$/, '') + '/' + params.toString();
      } else {
        path = path.replace(/\/$/, '') + '/?' + params.toString();
      }
      const apiData = await this.trackerRequest.get(path);
      notify({
        type: apiData.data.noticeType,
        title: apiData.data.title,
        message: apiData.data.message,
      });
      return apiData.data;
    } catch (_) {
      notify(NotificationTexts.serverRequestError);
      return null;
    }
  };

  requestPostGS = async (path: string, requestData: any = {}) => {
    if (this.isTokenExpired()) {
      notify(NotificationTexts.invalidToken);
      return;
    }
    try {
      const apiData = await this.trackerRequest.post(path, {
        ...requestData,
        ...this.trackerRequest.defaults.data,
      });
      notify({
        type: apiData.data.noticeType,
        title: apiData.data.title,
        message: apiData.data.message,
      });
      return apiData.data;
    } catch (_) {
      notify(NotificationTexts.serverRequestError);
      return null;
    }
  };

  requestGetScreenshot = async () => {
    if (this.isTokenExpired()) {
      notify(NotificationTexts.invalidToken);
      return;
    }
    try {
      const apiData = await this.screenshotRequest.get('');
      return apiData.data.status ? apiData.data : false;
    } catch (_) {
      notify(NotificationTexts.serverRequestError);
      return null;
    }
  };

  requestPostScreenshot = async (requestData: any = {}) => {
    if (this.isTokenExpired()) {
      notify(NotificationTexts.invalidToken);
      return;
    }
    try {
      const apiData = await this.screenshotRequest.post('', requestData);
      return apiData.data.status ? apiData.data : false;
    } catch (_) {
      notify(NotificationTexts.serverRequestError);
      return null;
    }
  };

  requestGetManifest = async (onDownloadProgress: (progressEvent: any) => void) => {
    if (this.isTokenExpired()) {
      notify(NotificationTexts.invalidToken);
      return;
    }
    try {
      const apiData = await this.manifestRequest.get('', {
        onDownloadProgress: onDownloadProgress,
      });
      return apiData.data;
    } catch (_) {
      notify(NotificationTexts.serverRequestError);
      return null;
    }
  };

  requestPostManifest = async (requestData: any = {}) => {
    if (this.isTokenExpired()) {
      notify(NotificationTexts.invalidToken);
      return;
    }
    try {
      const apiData = await this.manifestRequest.post('', requestData);
      return apiData.data;
    } catch (_) {
      notify(NotificationTexts.serverRequestError);
      return null;
    }
  };

  isTokenExpired() {
    return this.expire - new Date().getTime() <= 0;
  }
}
