import { TrackerContext } from 'app/custom-data-handler/data/defaultData';
import { StopTrackerButtonsList } from 'app/custom-data-handler/types';
import React, { useState } from 'react';
import {
  AppIcon,
  extendedComplete,
  fullyComplete,
  partiallyComplete,
  stopTrackerIcon,
} from '../../../../shell/icons';
import './DropdownButton.scss';

function DropdownButton() {
  const [stopOptions, toggleStopOptions] = useState<boolean | undefined>(false);
  return (
    <TrackerContext.Consumer>
      {(context) => (
        <div className={`dropdownButtonContainer ${stopOptions ? 'open' : ''}`}>
          {stopOptions ? (
            <div className="dropdownOptionsdiv">
              {context.stopDropDownButtons.map((dropDownButton: StopTrackerButtonsList) => (
                <button
                  type="button"
                  key={dropDownButton.slug.toLowerCase()}
                  className={`stopsessionoption ${dropDownButton.slug.toLowerCase()}`}
                  onClick={context.stopButtonClick}
                  value={dropDownButton.slug}
                >
                  <span className="icon">
                    <AppIcon icon={getButtonIcons(dropDownButton.slug)} />
                  </span>
                  <span className="text">{dropDownButton.title}</span>
                </button>
              ))}
            </div>
          ) : null}
          <button
            type="button"
            className="stopsessionoption stop"
            onClick={() => {
              toggleStopOptions(!stopOptions);
            }}
          >
            <span className="icon">
              <AppIcon icon={stopTrackerIcon} />
            </span>
            <span className="text">Stop Session</span>
          </button>
        </div>
      )}
    </TrackerContext.Consumer>
  );

  function getButtonIcons(slug: string) {
    switch (slug) {
      case 'EXTENDED':
        return extendedComplete;
      case 'PARTIAL':
        return partiallyComplete;
      case 'FULL':
        return fullyComplete;
      default:
        return stopTrackerIcon;
    }
  }
}

export default DropdownButton;
