import { AppIcon, takeScreenshot } from 'app/shell/icons';
import React from 'react';
import './ScreenshotButton.scss';

interface Props {
  screenshotClickFunc: any;
}

const screenshotButton = (props: Props) => (
  <div className="takeScreenshotbuttonDiv" title="Take Screenshot">
    <button type="button" className="takeScreenshotButton" onClick={props.screenshotClickFunc}>
      <span className="icon">
        <AppIcon icon={takeScreenshot} />
      </span>
      {/* <span className="text">Take Screenshot</span> */}
    </button>
  </div>
);

export default screenshotButton;
