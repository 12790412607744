import { ItemMetaData } from 'app/custom-data-handler/types';
import React from 'react';
import './OrderDetail.scss';

interface Props {
  name: string;
  data: string | number | boolean | ItemMetaData[];
}

function OrderDetail(props: Props) {
  return (
    <span className="singleDetail">
      <span className="optionTitle">{props.name}: </span>
      <span className="optionValue">{props.data}</span>
    </span>
  );
}

export default OrderDetail;
