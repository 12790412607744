import { SuggestedProduct } from 'app/custom-data-handler/types';
import { AppIcon, searchIcon } from 'app/shell/icons';
import React from 'react';
import './SelectProductButton.scss';

interface Props {
  selectProduct(): void;
  selectedProducts: SuggestedProduct[];
}

function SelectProductButton(props: Props) {
  return (
    <>
      {props.selectedProducts.length ? (
        <>
          <p style={{ margin: '8px 5px', fontSize: '18px' }}>
            <b>Selected Services:</b>
          </p>
          <div className="selected_product_listing_main_listing">
            {props.selectedProducts.map((selectedProd: SuggestedProduct) => (
              <span key={selectedProd.ID} className="single_product_nag">
                <span className="single_product_nag_image_title">
                  <img src={selectedProd.image} alt={selectedProd.name} />
                  <span className="single_product_nag_title">{selectedProd.name}</span>
                </span>
              </span>
            ))}
          </div>
        </>
      ) : null}
      <button type="button" className="show_seggested_modal_button" onClick={props.selectProduct}>
        <span className="icon">
          <AppIcon icon={searchIcon} />
        </span>
        <span className="text">Select Suggested Services</span>
      </button>
    </>
  );
}

export default SelectProductButton;
