export const statEffects = {
  warlock: {
    light: {
      discipline: [
        '1:43',
        '1:33',
        '1:25',
        '1:22',
        '1:08',
        '0:59',
        '0:51',
        '0:45',
        '0:41',
        '0:37',
        '0:32',
      ],
      strength: [
        '1:43',
        '1:33',
        '1:25',
        '1:22',
        '1:08',
        '0:59',
        '0:51',
        '0:45',
        '0:41',
        '0:37',
        '0:32',
      ],
      intellect: [
        '7:12',
        '6:22',
        '5:43',
        '5:00',
        '4:45',
        '4:31',
        '4:18',
        '4:07',
        '4:00',
        '3:52',
        '3:48',
      ],
      recovery: [
        '1:57',
        '1:43',
        '1:31',
        '1:22',
        '1:15',
        '1:08',
        '1:03',
        '0:59',
        '0:51',
        '0:46',
        '0:41',
      ],
    },
    dark: {
      discipline: [
        '2:25',
        '2:14',
        '2:05',
        '1:57',
        '1:50',
        '1:33',
        '1:20',
        '1:11',
        '1:04',
        '0:58',
        '0:53',
      ],
      strength: [
        '1:58',
        '1:49',
        '1:42',
        '1:35',
        '1:29',
        '1:16',
        '1:06',
        '0:58',
        '0:52',
        '0:47',
        '0:43',
      ],
      intellect: [
        '7:01',
        '6:23',
        '5:51',
        '5:23',
        '5:00',
        '4:47',
        '4:35',
        '4:24',
        '4:14',
        '4:05',
        '3:56',
      ],
      recovery: [
        '1:57',
        '1:43',
        '1:31',
        '1:22',
        '1:15',
        '1:08',
        '1:03',
        '0:59',
        '0:51',
        '0:46',
        '0:41',
      ],
    },
  },
  hunter: {
    light: {
      discipline: [
        '1:43',
        '1:33',
        '1:25',
        '1:22',
        '1:08',
        '0:59',
        '0:51',
        '0:45',
        '0:41',
        '0:37',
        '0:32',
      ],
      strength: [
        '2:00',
        '1:49',
        '1:40',
        '1:36',
        '1:20',
        '1:09',
        '1:00',
        '0:53',
        '0:48',
        '0:44',
        '0:37',
      ],
      intellect: [
        '7:12',
        '6:22',
        '5:43',
        '5:00',
        '4:45',
        '4:31',
        '4:18',
        '4:07',
        '4:00',
        '3:52',
        '3:48',
      ],
      mobility: [
        '0:34',
        '0:32',
        '0:30',
        '0:29',
        '0:26',
        '0:24',
        '0:22',
        '0:19',
        '0:16',
        '0:14',
        '0:11',
      ],
    },
    dark: {
      discipline: [
        '2:25',
        '2:14',
        '2:05',
        '1:57',
        '1:50',
        '1:33',
        '1:20',
        '1:11',
        '1:04',
        '0:58',
        '0:53',
      ],
      strength: [
        '1:58',
        '1:49',
        '1:42',
        '1:35',
        '1:29',
        '1:16',
        '1:06',
        '0:58',
        '0:52',
        '0:47',
        '0:43',
      ],
      intellect: [
        '7:01',
        '6:23',
        '5:51',
        '5:23',
        '5:00',
        '4:47',
        '4:35',
        '4:24',
        '4:14',
        '4:05',
        '3:56',
      ],
      mobility: [
        '0:34',
        '0:32',
        '0:30',
        '0:29',
        '0:26',
        '0:24',
        '0:22',
        '0:19',
        '0:16',
        '0:14',
        '0:11',
      ],
    },
  },
  titan: {
    light: {
      discipline: [
        '1:43',
        '1:33',
        '1:25',
        '1:22',
        '1:08',
        '0:59',
        '0:51',
        '0:45',
        '0:41',
        '0:37',
        '0:32',
      ],
      strength: [
        '1:43',
        '1:33',
        '1:25',
        '1:22',
        '1:08',
        '0:59',
        '0:51',
        '0:45',
        '0:41',
        '0:37',
        '0:32',
      ],
      intellect: [
        '7:12',
        '6:22',
        '5:43',
        '5:00',
        '4:45',
        '4:31',
        '4:18',
        '4:07',
        '4:00',
        '3:52',
        '3:48',
      ],
      resilience: [
        '0:52',
        '0:46',
        '0:41',
        '0:37',
        '0:33',
        '0:30',
        '0:28',
        '0:25',
        '0:21',
        '0:17',
        '0:14',
      ],
    },
    dark: {
      discipline: [
        '2:25',
        '2:14',
        '2:05',
        '1:57',
        '1:50',
        '1:33',
        '1:20',
        '1:11',
        '1:04',
        '0:58',
        '0:53',
      ],
      strength: [
        '1:37',
        '1:30',
        '1:24',
        '1:19',
        '1:14',
        '0:59',
        '0:51',
        '0:46',
        '0:41',
        '0:37',
        '0:32',
      ],
      intellect: [
        '7:01',
        '6:23',
        '5:51',
        '5:23',
        '5:00',
        '4:47',
        '4:35',
        '4:24',
        '4:14',
        '4:05',
        '3:56',
      ],
      resilience: [
        '0:52',
        '0:46',
        '0:41',
        '0:37',
        '0:33',
        '0:30',
        '0:28',
        '0:25',
        '0:21',
        '0:17',
        '0:14',
      ],
    },
  },
} as const;
