import { createContext } from 'react';
import { StopTrackerButtonsList, TrackerObjectType } from '../types';
export const defaultStopButtons: StopTrackerButtonsList[] = [
  {
    title: 'Order Complete 🙂',
    slug: 'FULL',
  },
  {
    title: 'Order Partially Complete',
    slug: 'PARTIAL',
  },
  {
    title: 'Extended Order Completed',
    slug: 'EXTENDED',
  },
];

export const defaultSelectedOrder: TrackerObjectType = {
  id: 0,
  orderId: 0,
  orderItemId: 0,
  platform: '',
  itemMeta: [],
  stream: '',
  productName: '',
  isOffline: false,
  activeRewards: false,
  askSuggested: false,
  screenshotPath: '',
  character: '',
  customerNote: '',
};

export const TrackerContext: React.Context<{
  stopButtonClick: (_event: any) => void;
  stopDropDownButtons: StopTrackerButtonsList[];
}> = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  stopButtonClick: (_event: any) => {},
  stopDropDownButtons: defaultStopButtons,
});

export const defaultRankImages = {
  glory: {
    before: 0,
    image: false,
    after: 0,
  },
  infamy: {
    before: 0,
    image: false,
    after: 0,
  },
  valor: {
    before: 0,
    image: false,
    after: 0,
  },
};
