import { makeCustomIcon } from './utils';

export const stadiaIcon = makeCustomIcon(
  'Stadia',
  120,
  120,
  'M 1.0085714,43.341385 C 0.3628571,43.80328 0.0028571,44.536039 0,45.290183 l 0,0 0,0.02994 c 0.00286,0.31791 0.068571,0.640096 0.2042857,0.9466 l 0,0 L 11.69,72.288238 c 0.508571,1.153312 1.821429,1.717851 3.011429,1.295872 l 0,0 c 7.52,-2.681557 33.561428,-11.165316 50.414285,-6.589133 l 0,0 c 0,0 -16.905714,0.977963 -32.192857,12.965852 l 0,0 c -0.861428,0.67716 -1.148571,1.85471 -0.707143,2.85548 l 0,0 c 1.241429,2.80416 3.732857,8.44099 5.142857,11.62721 l 0,0 1.98,4.64461 C 39.81,100.19013 41.315714,100.32271 41.98,99.324789 l 0,0 c 3.567143,-5.37167 9.581429,-8.05466 15.358571,-10.39692 l 0,0 c 5.918572,-2.39786 12.094286,-4.187 18.394286,-5.25192 l 0,0 C 82.445714,82.541169 89.3,82.237519 96.084286,82.837689 l 0,0 c 1.13,0.0998 2.178574,-0.59305 2.515714,-1.67365 l 0,0 5.18857,-16.582607 c 0.30572,-0.980814 -0.0457,-2.052867 -0.88,-2.655897 l 0,0 C 97.122857,57.734265 74.204286,43.656443 33.901429,49.311807 l 0,0 c 0,0 34.391428,-19.755996 78.052861,1.945947 l 0,0 c 1.32285,0.658628 2.92,-0.03279 3.36142,-1.438433 l 0,0 4.57429,-14.623828 c 0.0714,-0.22667 0.10571,-0.456192 0.11,-0.682863 l 0,0 0,-0.06985 c -0.0129,-0.832552 -0.45857,-1.622336 -1.21571,-2.051442 l 0,0 C 113.30429,29.290649 94.751429,19.999999 69.23,19.999999 l 0,0 c -19.561429,-0.0014 -43.214286,5.45578 -68.2214286,23.341386'
);

export default stadiaIcon;
