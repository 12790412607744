import React from 'react';
import './style.scss';

interface Props {
  note: string;
}

function CustomerNote({ note }: Props) {
  if (!note.length) {
    return null;
  }

  return (
    <div className="order-customer-note">
      <h5 className="customer-note-title">Customer Order Note:</h5>
      <span className="customer-note">{note}</span>
    </div>
  );
}

export default CustomerNote;
