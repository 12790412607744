import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { AppIcon, hideOptions /*, offlineMode*/, showOptions } from 'app/shell/icons';
import React from 'react';
import './OptionButton.scss';

interface Props {
  clickFunc: any;
  showHide: boolean;
}

function OptionButton(props: Props) {
  function _buildButton(
    className: string,
    title: string,
    icon: IconDefinition | string,
    text: string,
    click: () => void
  ) {
    return (
      <button type="button" className={className} title={title} onClick={click}>
        <span className="icon">
          <AppIcon icon={icon} />
        </span>
        <span className="text">{text}</span>
      </button>
    );
  }
  const { clickFunc, showHide } = props;
  return (
    <React.Fragment>
      {_buildButton(
        'show_hide_data',
        !showHide ? 'Close' : 'Open',
        !showHide ? showOptions : hideOptions,
        !showHide ? 'Show Options' : 'Hide Options',
        clickFunc
      )}
    </React.Fragment>
  );
}

export default OptionButton;
