import { NotificationType } from 'app/notifications/notifications';
import { NotificationText, NotificationTextType } from '../types';

const common =
  'You must open DIM from Orders Detail Page. Without it, DIM will not work as expected. If you did open DIM correclty and still getting this error, Please Contact Administrator right away.';
const tryReloading =
  "Try Reloading Page (try hard Reload by Reloading page while Holding down 'Shift' Key). If you are still getting this error, Please Contact Administrator";

const NotificationTexts: { [key in NotificationTextType]: NotificationText } = {
  authError: {
    type: 'error',
    title: 'Authentication Error',
    message: common,
  },
  tokenExpired: {
    type: 'warning',
    title: 'Authentication Token Expired!',
    message: 'Your Token is Expired Now. ' + common,
  },
  invalidToken: {
    type: 'error',
    title: 'Authentication Error',
    message: 'Your Token is not valid. ' + common,
  },
  serverRequestError: {
    type: 'error',
    title: 'Server Request Error',
    message: `This error occur when there is an issue with api. ` + tryReloading,
  },
  dimSideError: {
    type: 'error',
    title: 'GS DIM Error',
    message: tryReloading,
  },
  sessionStartError: {
    type: 'error',
    title: 'Start Session Error',
    message: 'No Order is Selected. Please Select Order from list.',
  },
  sessionStopError: {
    type: 'error',
    title: 'Stop Session Error',
    message: 'No Order is Selected. Please Select Order from list.',
  },
  invalidTwitchLink: {
    type: 'error',
    title: 'Invalid Twitch link',
    message: 'Invalid link added. Please add a valid Twitch link to continue.',
  },
  productAlreadySelected: {
    type: 'error',
    title: 'Already Selected',

    message: 'You already selected this item.',
  },
  maxProductSelected: {
    type: 'error',
    title: 'Maximum selected',
    message: 'You can only select 3 services',
  },
  offlineLoadingFailed: {
    type: 'error',
    title: 'Unable to Load Offline Data',
    message: 'There must be an error with Bungie manifest File. Unable to get Manifest Items Data',
  },
  offlineTakingLongTime: {
    type: 'error',
    title: 'Offline Manifest',
    message:
      'Loading Offline Manifest is taking very long time. Make sure you Internet is Working. Either you can wait or you can reload page, It will again try to get offline manifest Again.',
  },
  noResponse: {
    type: 'error',
    title: 'No Response',
    message: 'No Response recieved from Server.',
  },
  gettingUpdatedManifest: {
    type: 'info',
    title: 'Getting Updated Manifest',
    message: 'Dim is getting new Offline Data to store on Local Machine for better performance.',
  },
  reloadIfNeeded: {
    type: 'info',
    title: 'Reload If needed',
    message:
      'There seems to be an issue in Loading New Updated Manifest File. Please Reload the page in order to get latest Manifest',
  },
};

export default NotificationTexts;

export const tokenWillExpireNotification = (expire: number) => {
  const type: NotificationType = 'warning';
  return {
    type,
    title: `Authentication Token will Expire in ${(
      (new Date(expire).getTime() - new Date().getTime()) /
      60000
    ).toFixed()} Minutes`,
    message: `Your Token will expire within ${(
      (new Date(expire).getTime() - new Date().getTime()) /
      60000
    ).toFixed()} minutes. You are Required to Open DIM again in order to continue.`,
  };
};
