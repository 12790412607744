import { TrackerObjectType } from 'app/custom-data-handler/types';
import React from 'react';
import './TrackerList.scss';

interface Props {
  trackerList: TrackerObjectType[];
  change(event: React.ChangeEvent<HTMLSelectElement>): void;
}

function TrackerList(props: Props) {
  return (
    <>
      <div className="characterList" title="Select Session">
        <select className="customSelect" onChange={props.change}>
          <option value="-1">Select Tracker</option>
          {props.trackerList.map((element: TrackerObjectType, index: number) => (
            <option key={element.id} value={index}>
              #{element.orderId}, {element.productName}
            </option>
          ))}
        </select>
      </div>
      <hr />
    </>
  );
}
export default TrackerList;
