import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './TimeModal.scss';

interface Props {
  onDateModalSelect(string: string): void;
}

function TimeModal(props: Props) {
  const [dateTime, setDateTime] = useState('');
  const [showModal, setshowModal] = useState(true);
  return (
    <CSSTransition
      in={showModal}
      timeout={200}
      unmountOnExit
      onExited={() => props.onDateModalSelect(dateTime)}
      classNames="show_modal_container"
    >
      <div className="time_modal_container">
        <CSSTransition in={showModal} timeout={500} unmountOnExit={false} classNames="show_modal">
          <div className="time_modal">
            <div className="time_modal_header">Next Estimated Time Frame</div>
            <div className="time_modal_body">
              <p>
                Enter date and time for when you expect to work on this order the next time. This
                will be sent to the customer as an estimated time frame.
              </p>
              <input
                type="datetime-local"
                min={new Date().getTime()}
                defaultValue={dateTime}
                onChange={(e) => setDateTime(e.currentTarget.value)}
              />
            </div>
            <div className="time_modal_footer">
              <button
                type="button"
                className="cancel_time_modal"
                onClick={() => {
                  setDateTime('');
                  setshowModal(false);
                }}
              >
                Cancel
              </button>
              <button type="button" className="okay_time_modal" onClick={() => setshowModal(false)}>
                Okay
              </button>
            </div>
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  );
}
export default TimeModal;
