import { AppIcon, startTrackerIcon } from 'app/shell/icons';
import React from 'react';
import './StartTrackerButton.scss';

interface Props {
  startClickFunc: any;
}

const startTrackerButton = (props: Props) => (
  <div className="startButtonDiv" title="Start Tracker Button">
    <button type="button" className="startButton" onClick={props.startClickFunc}>
      <span className="icon">
        <AppIcon icon={startTrackerIcon} />
      </span>
      <span className="text">Start Tracker</span>
    </button>
  </div>
);

export default startTrackerButton;
