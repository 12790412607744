import { AppIcon, removeSession as removeSessionIcon } from 'app/shell/icons';
import React from 'react';
import './RemoveSession.scss';

interface Props {
  resetClickFunc: any;
}

const removeSession = (props: Props) => (
  <div className="removeSessionbuttonDiv" title="Remove Session">
    <button type="button" className="removeSessionButton" onClick={props.resetClickFunc}>
      <span className="icon">
        <AppIcon icon={removeSessionIcon} />
      </span>
      <span className="text">Remove Session</span>
    </button>
  </div>
);

export default removeSession;
