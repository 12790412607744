import { OfflineManifestLoader } from 'app/custom-data-handler/types';
import React from 'react';
import './OfflineLoader.scss';

function OfflineLoader(props: OfflineManifestLoader) {
  return (
    <div className="offline-manifest-loader-main-div">
      <span className="offline-manifest-loader" style={{ width: `${props.progress}%` }}></span>
      <span className="offline-manifest-loader-text">
        Offline Manifest Loaded: {props.progress}%
      </span>
    </div>
  );
}

export default OfflineLoader;
