import { editQuantity, removeCustomData, updateRankData } from 'app/custom-data-handler/actions';
import { CustomData, RankData } from 'app/custom-data-handler/types';
import store from 'app/store/store';
import { RootState } from 'app/store/types';
import React from 'react';
import { connect } from 'react-redux';
import './SingleItem.scss';

interface Props {
  mainList: object[];
  ranks: RankData[];
}

const singleItem = (props: Props) => {
  function handleItemRemoveClick(indexedKey: number) {
    store.dispatch(removeCustomData(indexedKey));
  }

  function handleQuantityChange(event: React.ChangeEvent<HTMLInputElement>, indexedKey: number) {
    store.dispatch(editQuantity({ id: indexedKey, value: parseInt(event.target.value, 10) }));
  }

  function handleRankDataChange(index: number, type: string, value: number) {
    store.dispatch(
      updateRankData({
        index,
        type,
        value,
      })
    );
  }

  function statsProperty(element: CustomData, index: number) {
    switch (element.type) {
      case 'Shaders':
      case 'Consumables':
      case 'Engrams':
        return (
          <input
            className="customQuantity"
            type="number"
            id={`input_` + element.id}
            value={element.stats}
            onChange={(e) => handleQuantityChange(e, index)}
          />
        );
      case 'Unknown': // Added Support for editable Ether and Key code states as their type was unknown at the time of writing the code
        if (element.name === 'Ether' || element.name === 'Key Code') {
          return (
            <input
              className="customQuantity"
              type="number"
              id={`input_` + element.id}
              value={element.stats}
              onChange={(e) => handleQuantityChange(e, index)}
            />
          );
        }
        return <p>{element.stats}</p>;
      default:
        return <p>{element.stats}</p>;
    }
  }

  function typeProperty(element: CustomData) {
    switch (element.type) {
      case 'Engrams':
        return <p>Power Level</p>;
      default:
        return <p>{element.type}</p>;
    }
  }

  const { mainList, ranks } = props;
  const ranksList = !ranks.length ? null : (
    <div className="singleRank">
      <p className="rank_title">Ranks Data</p>
      <div className="rank_data_old_new">
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Before</th>
              <th>After</th>
            </tr>
          </thead>
          <tbody>
            {ranks.map((singleRank: RankData, index: number) => (
              <tr key={`rank_${singleRank.id}`}>
                <th>{singleRank.rankType}</th>
                <td>
                  <input
                    type="number"
                    className="rank_inputs"
                    value={singleRank.oldProgress}
                    onChange={(e) =>
                      handleRankDataChange(index, 'oldProgress', Number(e.target.value))
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="rank_inputs"
                    value={singleRank.newProgress}
                    onChange={(e) =>
                      handleRankDataChange(index, 'newProgress', Number(e.target.value))
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const list = mainList.map((element: CustomData, index) => (
    <div className="singleItem" key={index} title={element.name}>
      <div className="singleImageContainer">
        <img className="iconImage" src={`${element.icon}`} alt={element.name} />
      </div>
      <div className="infocontainer">
        <p>{element.name}</p>
        {statsProperty(element, index)}
        {typeProperty(element)}
        <button type="button" className="removeButton" onClick={() => handleItemRemoveClick(index)}>
          Remove
        </button>
      </div>
    </div>
  ));

  return (
    <>
      {ranksList}
      {list}
    </>
  );
};

function mapStateToProps(state: RootState) {
  return {
    mainList: state.custom.added,
    ranks: state.custom.ranks,
  };
}

export default connect(mapStateToProps)(singleItem);
