import NotificationTexts from 'app/custom-data-handler/data/notificationText';
import { notify } from 'app/custom-data-handler/functions';
import { SuggestedProduct } from 'app/custom-data-handler/types';
import axios, { AxiosInstance, Canceler } from 'axios';
import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './ProductModal.scss';

interface Props {
  previousSelectedProducts: SuggestedProduct[];
  onProductModalSelect(arg1: SuggestedProduct[]): void;
  // showNotification(arg1: string, arg2: string, arg3: string): void;
  ajaxCallInstance: AxiosInstance;
}

function ProductModal(props: Props) {
  const CancelToken = axios.CancelToken;
  let cancelAxios: Canceler | undefined;
  const [products, setProduct] = useState<SuggestedProduct[]>(props.previousSelectedProducts);
  const [searchedProducts, setSearchedProduct] = useState([]);
  const [loadingState, changeLoadingState] = useState(false);
  const [showModal, setshowModal] = useState(true);
  const axiosCall = async (keyword: string) => {
    if (props.ajaxCallInstance === null) {
      return;
    }
    if (cancelAxios) {
      cancelAxios();
      changeLoadingState(false);
    }
    changeLoadingState(true);
    const apiData = await props.ajaxCallInstance.post(
      `search`,
      {
        keyword,
        ...props.ajaxCallInstance.defaults.data,
      },
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelAxios = c;
        }),
      }
    );
    changeLoadingState(false);
    notify({
      type: apiData.data.noticeType,
      title: apiData.data.title,
      message: apiData.data.message,
    });
    if (apiData.data.error) {
      setSearchedProduct([]);
      return false;
    }
    setSearchedProduct(apiData.data.products);
  };
  return (
    <CSSTransition
      in={showModal}
      timeout={200}
      unmountOnExit
      onExited={() => props.onProductModalSelect(products)}
      classNames="show_modal_container"
    >
      <div className="product_modal_container">
        <CSSTransition in={showModal} timeout={500} unmountOnExit={false} classNames="show_modal">
          <div className="product_modal">
            <div className="product_modal_header">Suggest Services</div>
            <div className="product_modal_body">
              <p>Search product from guardian.services</p>
              <input type="text" onKeyUp={(e) => axiosCall(e.currentTarget.value)} />
              <div className="selected_product_listing">
                {products.map((selectedProd: SuggestedProduct) => (
                  <span key={selectedProd.ID} className="single_product_nag">
                    <span className="single_product_nag_image_title">
                      <img src={selectedProd.image} alt={selectedProd.name} />
                      <span className="single_product_nag_title">{selectedProd.name}</span>
                    </span>
                    <span
                      className="single_product_nag_remove"
                      onClick={(event) => {
                        const prodIndex = products.findIndex(
                          (prod: SuggestedProduct) => Number(prod.ID) === Number(selectedProd.ID)
                        );
                        if (prodIndex !== -1) {
                          event.stopPropagation();
                          event.currentTarget.parentElement;
                          products.splice(prodIndex, 1);
                          setProduct([...products]);
                        }
                      }}
                    >
                      &times;
                    </span>
                  </span>
                ))}
              </div>
              <div className="product_modal_searched_list">
                {loadingState ? (
                  <div className="input_loader">
                    <span className="search_loader_icon"></span>
                  </div>
                ) : null}
                {searchedProducts.map((element: SuggestedProduct) => {
                  const prodIndex: number = products.findIndex(
                    (prod: SuggestedProduct) => Number(prod.ID) === Number(element.ID)
                  );
                  return (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                    <p
                      className={`single_searched_product ${prodIndex !== -1 ? 'selected' : ''}`}
                      key={element.ID}
                      onClick={(e) => {
                        if (prodIndex !== -1) {
                          notify(NotificationTexts.productAlreadySelected);
                          return false;
                        }
                        if (products.length >= 3) {
                          notify(NotificationTexts.maxProductSelected);
                          return false;
                        }
                        e.currentTarget.classList.add('selected');
                        setProduct([...products, element]);
                      }}
                    >
                      <span className="product_name_image">
                        <img src={element.image} alt={element.name} />
                        <span dangerouslySetInnerHTML={{ __html: element.name }}></span>
                      </span>
                      {prodIndex !== -1 ? (
                        <span
                          className="clear_selected_services"
                          onClick={(event) => {
                            event.stopPropagation();
                            event.currentTarget.parentElement;
                            products.splice(prodIndex, 1);
                            setProduct([...products]);
                          }}
                        >
                          &times;
                        </span>
                      ) : null}
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="product_modal_footer">
              <button
                type="button"
                className="cancel_product_modal"
                onClick={() => {
                  products.splice(0, products.length);
                  setshowModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="okay_product_modal"
                onClick={() => setshowModal(false)}
              >
                Okay
              </button>
            </div>
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  );
}
export default ProductModal;
