import { removeAllData, removeRankData } from 'app/custom-data-handler/actions';
import AjaxCall from 'app/custom-data-handler/classes/ajax';
// Componenets Import
import AjaxLoader from 'app/custom-data-handler/components/ajax-loader/AjaxLoader';
import CompareButton from 'app/custom-data-handler/components/buttons/compare-button/CompareButton';
import OptionButton from 'app/custom-data-handler/components/buttons/option-button/OptionButton';
import RemoveSession from 'app/custom-data-handler/components/buttons/remove-session-button/RemoveSession';
import ScreenshotButton from 'app/custom-data-handler/components/buttons/screenshot-button/ScreenshotButton';
import SelectProductButton from 'app/custom-data-handler/components/buttons/select-product-button/SelectProductButton';
import StartTrackerButton from 'app/custom-data-handler/components/buttons/start-tracker-button/StartTrackerButton';
import StopOptionsButton from 'app/custom-data-handler/components/buttons/stop-option-button/StopOptionsButton';
import ProductModal from 'app/custom-data-handler/components/modal/ProductModal';
import TimeModal from 'app/custom-data-handler/components/modal/TimeModal';
import OfflineListing from 'app/custom-data-handler/components/offline/OfflineListing';
import OrderDetail from 'app/custom-data-handler/components/order-datail/OrderDetail';
import SingleItem from 'app/custom-data-handler/components/single-item/SingleItem';
import TrackerList from 'app/custom-data-handler/components/tracker-list/TrackerList';
import 'app/custom-data-handler/CustomDataHandler.scss';
import {
  defaultRankImages,
  defaultSelectedOrder,
  defaultStopButtons,
  TrackerContext,
} from 'app/custom-data-handler/data/defaultData';
import NotificationTexts, {
  tokenWillExpireNotification,
} from 'app/custom-data-handler/data/notificationText';
import {
  formatExtendedOrderDateTime,
  getRankImageDataUrl,
  getSesstionData,
  getUpdatedTriumph,
  notify,
  triggerRankDispatch,
} from 'app/custom-data-handler/functions';
import {
  CDHProps,
  CDHState,
  CustomTriumph,
  invalidWorkerSesstionType,
  NewItem,
  NewStore,
  RankData,
  RankImageData,
  SuggestedProduct,
  TrackerObjectType,
} from 'app/custom-data-handler/types';
import { DimStore } from 'app/inventory/store-types';
import { refresh } from 'app/shell/refresh';
import store from 'app/store/store';
import { RootState } from 'app/store/types';
import { DestinyProgression } from 'bungie-api-ts/destiny2';
import { DestinyProfileResponse, DestinyRecordComponent } from 'bungie-api-ts/destiny2/interfaces';
import { del, get, set } from 'idb-keyval';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import CustomerNote from './components/falcon/CustomerNote';

export class AddedDataContainer extends React.Component<CDHProps, CDHState> {
  state = {
    show: false,
    loading: false,
    trackerList: [],
    expire: 0,
    userValidated: false,
    isListLoaded: false,
    selectedOrder: defaultSelectedOrder,
    index: -1,
    screenShots: [],
    isOfflineMode: false,
    isOpenedOffline: false,
    offlineView: false,
    isSessionStarted: false,
    stopButtons: [],
    showTimeModal: false,
    showProductModal: false,
    triumphsRecordingEnable: true,
  };

  indexedDbWorking = Boolean(window.indexedDB);
  selectedExtendedOrderDate = '';
  selectedSuggestedProduct: SuggestedProduct[] = [];
  ajaxCall!: AjaxCall;

  timeOutForRefresh: any = null;
  databaseLoadedInitially = false;

  componentDidMount = () => {
    const { workerId, token, onlyOffline, expire } = getSesstionData();

    if (workerId === 0 || token === '0' || expire === 0) {
      this.invalidWorkerSesstion('auth');
      return;
    }

    if (expire - new Date().getTime() <= 0) {
      this.invalidWorkerSesstion('tokenExpire');
      return;
    }

    this.startSessionTimeouts(expire);

    this.ajaxCall = new AjaxCall(workerId, token, expire);

    this.setState({
      expire,
      userValidated: true,
      isOpenedOffline: onlyOffline === 1,
      offlineView: onlyOffline === 1,
    });

    const sessionData: string | null = window.localStorage.getItem('sessionData');
    if (sessionData === null || this.state.isSessionStarted) {
      return;
    }
    const parsedSessionData: any = JSON.parse(sessionData);
    this.setState({
      isSessionStarted: parsedSessionData?.sessionStatus,
      selectedOrder: parsedSessionData?.selectedOrder,
      screenShots: parsedSessionData?.screenshots,
      index: parsedSessionData?.selectedIndex,
    });
  };

  componentDidUpdate = (prevProps: CDHProps) => {
    if (!this.databaseLoadedInitially) {
      this.databaseLoadedInitially = this.props.databaseLoaded;
    }
    if (!this.state.selectedOrder || !this.state.selectedOrder.activeRewards) {
      return;
    }
    if (prevProps.currentItems.length === 0) {
      return;
    }
    const tempItems: NewItem[] = [];
    this.props.currentItems.forEach((element: NewItem) => {
      if (
        !prevProps.currentItems.find(
          (singleItem) =>
            singleItem.id === element.id &&
            singleItem.index === element.index &&
            singleItem.hash === element.hash
        )
      ) {
        tempItems.push(element);
      }
    });
    if (tempItems.length) {
      this.ajaxCall.sendActiveRewards(tempItems, this.state.selectedOrder.id);
    }
  };

  startSessionTimeouts = (expire: number) => {
    setTimeout(() => {
      notify(tokenWillExpireNotification(expire));
    }, Math.max(expire - new Date().getTime() - 300000, 1000));

    setTimeout(this.setTokenExpired, expire - new Date().getTime());
  };

  isTokenExpire = () => {
    const isExpired = this.state.expire - new Date().getTime() <= 0;
    this.removeContentIfTokenExpired(isExpired);
    return isExpired;
  };

  invalidWorkerSesstion = (type: invalidWorkerSesstionType) => {
    switch (type) {
      case 'auth':
        setTimeout(() => {
          this.removeContentIfTokenExpired(true);
          notify(NotificationTexts.authError);
        }, 1000);
        break;
      case 'tokenExpire':
        setTimeout(this.setTokenExpired, 1000);
        break;
    }
  };

  setTokenExpired = () => {
    this.setState({
      expire: 0,
      userValidated: false,
    });
    window.sessionStorage.clear();
    this.removeContentIfTokenExpired(true);
    notify(NotificationTexts.tokenExpired);
  };

  removeContentIfTokenExpired = (isExpired: boolean) => {
    if (isExpired) {
      const contentElement = document.getElementById('app') as HTMLDivElement;
      if (contentElement !== null) {
        const notificationElement = document.getElementsByClassName(
          'notifications-container'
        )[0] as HTMLDivElement;
        document.body.prepend(notificationElement);
        contentElement.id = '';
        contentElement.innerHTML =
          '<div style="text-align:center"><h1>TOKEN AUTHENTICATION FAILED</h1><p>Your Token is not valid or Does not Exist. Without it, DIM will not work as expected. If you did open DIM Correctly and still getting this error, Please Contact Administrator right away</p></div>';
      }
      window.stop();
    }
  };

  handleOptionToggle = async () => {
    const showOptions = !this.state.show;
    this.setState((prevState) => ({ show: !prevState.show }));
    if (showOptions) {
      this.setState({ loading: true });
      const response = await this.ajaxCall.getGSSettings();
      if (!response) {
        this.setState({ loading: false, stopButtons: defaultStopButtons });
      } else {
        this.setState({
          loading: false,
          stopButtons: response.buttons,
          triumphsRecordingEnable: response.triumphs_dim_status,
        });
      }
    }
    if (this.state.isListLoaded || this.state.isSessionStarted) {
      return;
    }
    this.setState({ loading: true });
    const response = await this.ajaxCall.getSessionsList();
    if (!response) {
      this.setState((prevState) => ({ loading: false, show: !prevState.show }));
    } else {
      this.setState({
        loading: false,
        isListLoaded: true,
        trackerList: response.list,
        stopButtons: response.buttons,
      });
    }
  };

  handleTrackerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const index = parseInt(event.currentTarget.value, 10);

    if (index === -1 && this.state.selectedOrder !== null) {
      this.setState({
        selectedOrder: defaultSelectedOrder,
        index: -1,
      });
      return;
    }

    this.setState((prevState) => ({
      selectedOrder: prevState.trackerList[index],
      index,
    }));
  };

  _printOrderDetails = () => {
    const selectedOrder: TrackerObjectType | null = this.state.selectedOrder;
    const orderData: JSX.Element[] = [];
    const ignoreData: string[] = [
      'id',
      'isOffline',
      'askSuggested',
      'activeRewards',
      'screenshotPath',
      'customerNote',
    ];
    const formatedKeyDisplayValue: { [key: string]: string } = {
      orderId: 'Order ID',
      orderItemId: 'Order Item ID',
      platform: 'Platform',
      itemMeta: 'Item Meta',
      stream: 'Stream',
      productName: 'Product Name',
      character: 'Character',
    };

    _.forIn(selectedOrder, (value, key) => {
      if (ignoreData.includes(key)) {
        return;
      }
      if (key === 'itemMeta') {
        const itemMetaData = Array.isArray(value)
          ? value
          : Array.isArray(Object.values(value))
          ? Object.values(value)
          : [];
        const itemMetaHTML: any = itemMetaData.map((itemValue, index) => (
          <OrderDetail key={index} name={itemValue.display_key} data={itemValue.value} />
        ));
        orderData.push(itemMetaHTML);
        return;
      }
      const stringKey = formatedKeyDisplayValue[key] || key.replace(/_/g, ' ');
      orderData.push(<OrderDetail key={`${key}-${value}`} name={stringKey} data={value} />);
    });
    return orderData;
  };

  captureScreenshot = async (path: string) => {
    // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
    const appContainer = document!.querySelector('#content') as HTMLElement;
    const html = appContainer === null ? '' : appContainer.innerHTML;
    const data = this.getAllItemData(true);
    const response = await this.ajaxCall.requestPostScreenshot({
      path,
      html,
      data,
    });
    return response?.status ? response : false;
  };

  getAllItemData = (stringOnly: boolean) => {
    const stores = store.getState().inventory.stores;
    const fixedStores: NewStore[] = [];
    _.forIn(stores, (storeObject) => {
      const newStore: any = _.pick(storeObject, [
        'id',
        'name',
        'level',
        'powerLevel',
        'background',
        'icon',
        'items',
      ]);
      const newItems: NewItem[] = [];
      _.forIn(newStore.items, (singleStore) => {
        const newItem: any = _.pick(singleStore, [
          'id',
          'index',
          'hash',
          'owner',
          'basePower',
          'name',
          'description',
          'icon',
          'type',
          'typeName',
          'tier',
          'destinyVersion',
          'amount',
        ]);
        newItems.push(newItem);
      });
      newStore.items = newItems;
      fixedStores.push(newStore);
    });
    return stringOnly ? JSON.stringify(fixedStores) : fixedStores;
  };

  removeSession = async () => {
    if (!confirm('You Are About to Remove Current Session. Are you sure?')) {
      return false;
    }
    if (!confirm("You can't revert this action. ARE YOU SURE?")) {
      return false;
    }
    const reset = await this.resetRemovedSession();
    if (reset === null) {
      notify(NotificationTexts.dimSideError);
    }
    if (!reset) {
      return false;
    }
    this.setState((_prevState) => ({
      isSessionStarted: false,
      index: -1,
      selectedOrder: defaultSelectedOrder,
    }));
    window.localStorage.removeItem('sessionData');
    if (this.indexedDbWorking) {
      del('beforeRecords');
    } else {
      localStorage.removeItem('beforeRecords');
    }
  };

  resetRemovedSession = async () => {
    if (this.state.selectedOrder === null) {
      return null;
    }
    const selectedOrder: TrackerObjectType = this.state.selectedOrder;
    this.setState({ loading: true });
    const response = await this.ajaxCall.resetRemovedSession(selectedOrder);
    this.setState({ loading: false });
    return response ? true : false;
  };

  saveWorkerScreenshot = async () => {
    const selectedOrder: TrackerObjectType = this.state.selectedOrder;
    if (selectedOrder === null) {
      return null;
    }
    const path = `${selectedOrder.screenshotPath}.${selectedOrder.orderId}.${selectedOrder.orderItemId}.${selectedOrder.id}`;
    this.setState({ loading: true });
    const isComplete: any = await this.captureScreenshot(path);
    this.setState({ loading: false });
    if (!isComplete) {
      return false;
    }
    const response = await this.ajaxCall.saveScreenshotPath(
      Number(isComplete.screenshot_id),
      isComplete.screenshot_path,
      this.state.selectedOrder
    );
    if (!response) {
      this.setState({ loading: false });
      return false;
    }
    const screenshotData = {
      title: 'Worker',
      path: isComplete.screenshot_path,
      id: Number(isComplete.screenshot_id),
    };
    let sessionData: any = window.localStorage.getItem('sessionData');
    sessionData = JSON.parse(sessionData);
    const screenShots = [...sessionData.screenshots, screenshotData];
    sessionData = {
      ...sessionData,
      screenshots: screenShots,
    };
    this.setState({ screenShots });
    window.localStorage.setItem('sessionData', JSON.stringify(sessionData));
    return true;
  };

  startTracker = async () => {
    if (this.isTokenExpire()) {
      notify(NotificationTexts.authError);
      return;
    }
    if (this.state.selectedOrder.id === 0 || this.state.index === -1) {
      notify(NotificationTexts.sessionStartError);
      return;
    }
    const selectedOrder = this.state.selectedOrder;
    const index = this.state.index;
    const path = `${selectedOrder.screenshotPath}.${selectedOrder.orderId}.${selectedOrder.orderItemId}.${selectedOrder.id}`;
    this.setState({ loading: true });
    const isComplete = await this.captureScreenshot(path);
    if (!isComplete) {
      this.setState({ loading: false });
      return false;
    }
    const screenshotId = Number(isComplete.screenshot_id);
    const screenshotPath = isComplete.screenshot_path;
    const response = await this.ajaxCall.startSession(screenshotId, screenshotPath, selectedOrder);
    if (!response) {
      this.setState({ loading: false });
      return false;
    }
    store.dispatch(removeAllData());
    // Removing Ranks
    store.dispatch(removeRankData());
    const screenshotData = {
      title: 'Start',
      path: screenshotPath,
      id: screenshotId,
    };
    const sessionData = {
      sessionStatus: true,
      selectedOrder,
      selectedIndex: index,
      ranks: {
        glory: this.props.glory,
        infamy: this.props.infamy,
        valor: this.props.valor,
      },
      screenshots: [screenshotData],
    };
    window.localStorage.setItem('sessionData', JSON.stringify(sessionData));
    this.setState({ isSessionStarted: true, screenShots: [screenshotData] });
    if (this.indexedDbWorking) {
      set('beforeRecords', this.props.profileRecords);
    } else {
      localStorage.setItem('beforeRecords', JSON.stringify(this.props.profileRecords));
    }
    if (this.state.selectedOrder.activeRewards) {
      this.timeOutForRefresh = setInterval(refresh, 60000);
    }
    this.setState({ loading: false });
    return true;
  };

  stopTracker = async (event: React.MouseEvent<HTMLInputElement>) => {
    const stopType = event.currentTarget.value;
    const isValid = this.validateBeforeStopTracker(stopType);
    if (!isValid) {
      return;
    }
    const selectedOrder = this.state.selectedOrder;
    const path = `${selectedOrder.screenshotPath}.${selectedOrder.orderId}.${selectedOrder.orderItemId}.${selectedOrder.id}`;
    this.setState({ loading: true });

    const unFilteredItemAdded = store.getState().custom.added;
    const itemAdded = unFilteredItemAdded.filter((el) => el !== undefined);
    const noteElement = document.getElementById('worker_order_note') as HTMLSelectElement;
    const orderNote = noteElement.value;
    let twitchLink = '';
    if (this.state.selectedOrder.stream.toLowerCase() === 'yes') {
      twitchLink = this.validateTwitchLink();
      if (twitchLink === '') {
        return;
      }
      if (
        `${selectedOrder.platform}`.toLowerCase() !== 'pc' &&
        `${selectedOrder.platform}`.toLowerCase() !== 'steam'
      ) {
        alert(
          'Please make sure you have UN-LINKED your Twitch profile if you were streaming from a console.'
        );
      }
    }

    const triumphs: CustomTriumph[] = await this.getCollectedTriumphs();

    const isComplete = await this.captureScreenshot(path);
    if (!isComplete) {
      this.setState({ loading: false });
      return false;
    }

    const screenshotId = isComplete.screenshot_id;
    const screenshotPath = isComplete.screenshot_path;
    const response = await this.ajaxCall.stopTracker(
      {
        screenshotId,
        screenshotPath,
        itemAdded,
        orderNote,
        twitchLink,
        stopType,
        ranksImagesData: await this.getRanksImages(),
        extendedOrderDateTime: formatExtendedOrderDateTime(
          new Date(this.selectedExtendedOrderDate)
        ),
        suggestedProduct: [...this.selectedSuggestedProduct.map((e: SuggestedProduct) => e.ID)],
        triumphs,
      },
      selectedOrder
    );
    if (!response) {
      this.setState({ loading: false });
      return false;
    }
    store.dispatch(removeAllData());
    window.localStorage.removeItem('sessionData');
    this.setState({
      show: false,
      trackerList: [],
      isListLoaded: false,
      selectedOrder: defaultSelectedOrder,
      index: -1,
      isSessionStarted: false,
    });
    if (this.timeOutForRefresh !== null) {
      clearInterval(this.timeOutForRefresh);
      this.timeOutForRefresh = null;
    }
    if (this.indexedDbWorking) {
      del('beforeRecords');
    } else {
      localStorage.removeItem('beforeRecords');
    }
    this.setState({ loading: false });
    return true;
  };

  validateBeforeStopTracker = (stopType: string) => {
    if (this.isTokenExpire()) {
      notify(NotificationTexts.authError);
      return false;
    }
    if (this.state.selectedOrder.id === 0 || this.state.index === -1) {
      notify(NotificationTexts.sessionStopError);
      return false;
    }

    if (
      stopType === 'FULL' &&
      !window.confirm(
        'Are you sure this order is complete? Make sure there are no other items remaining.'
      )
    ) {
      return false;
    }

    if (stopType === 'EXTENDED' && this.selectedExtendedOrderDate === '') {
      this.setState({ showTimeModal: true });
      return false;
    }
    if (
      stopType === 'EXTENDED' &&
      !confirm(
        `Did you write the following in the note box?
- What is remaining
- When you are going to do it.
Currently Selected Date and Time: ${this.selectedExtendedOrderDate}
Click Cancel to Change`
      )
    ) {
      this.selectedExtendedOrderDate = '';
      this.setState({ showTimeModal: true });
      return false;
    }
    return true;
  };

  validateTwitchLink = () => {
    const twitchLinkElement = document.getElementById('worker_twitch_link') as HTMLSelectElement;
    const twitchLink = twitchLinkElement?.value || '';
    const urlRegex =
      /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
    if (
      twitchLinkElement !== null &&
      twitchLinkElement !== undefined &&
      (twitchLink === '' || !urlRegex.test(twitchLink))
    ) {
      notify(NotificationTexts.invalidTwitchLink);
      this.setState({ loading: false });
      return '';
    }
    return twitchLink;
  };

  getCollectedTriumphs = async () => {
    let triumphs: CustomTriumph[] = [];

    if (this.state.triumphsRecordingEnable) {
      let beforeRecordsJSON: { [key: number]: DestinyRecordComponent } = {};

      try {
        if (this.indexedDbWorking) {
          beforeRecordsJSON = await get<{ [key: number]: DestinyRecordComponent }>('beforeRecords');
        } else {
          beforeRecordsJSON = JSON.parse(localStorage.getItem('beforeRecords') || '{}');
        }
      } catch (error) {
        beforeRecordsJSON = {};
      }

      triumphs = await getUpdatedTriumph(this.props.profileRecords, beforeRecordsJSON);
    }
    return triumphs;
  };

  getRanksImages = async () => {
    if (this.state.isOfflineMode) {
      return defaultRankImages;
    }

    const rankImageDataObject: RankImageData = _.cloneDeep(defaultRankImages);
    // Worker will get to update glory, valor, and infamy rank if he right click on compare button
    rankImageDataObject.glory.before = this.getRankProgress('glory', 'oldProgress');
    rankImageDataObject.glory.after = this.getRankProgress('glory', 'newProgress');
    rankImageDataObject.glory.image = await getRankImageDataUrl(
      'glory',
      rankImageDataObject.glory.before,
      rankImageDataObject.glory.after
    );
    rankImageDataObject.infamy.before = this.getRankProgress('infamy', 'oldProgress');
    rankImageDataObject.infamy.after = this.getRankProgress('infamy', 'newProgress');
    rankImageDataObject.infamy.image = await getRankImageDataUrl(
      'infamy',
      rankImageDataObject.infamy.before,
      rankImageDataObject.infamy.after
    );
    rankImageDataObject.valor.before = this.getRankProgress('valor', 'oldProgress');
    rankImageDataObject.valor.after = this.getRankProgress('valor', 'newProgress');
    rankImageDataObject.valor.image = await getRankImageDataUrl(
      'valor',
      rankImageDataObject.valor.before,
      rankImageDataObject.valor.after
    );

    return rankImageDataObject;
  };

  getRankProgress = (type: string, get: string) => {
    if (!this.props.ranks.length) {
      triggerRankDispatch(
        { glory: this.props.glory, infamy: this.props.infamy, valor: this.props.valor },
        this.state.isOfflineMode
      );
    }
    let rank: RankData | null = null;
    this.props.ranks.forEach((singleRank) => {
      if (singleRank.slug === type) {
        rank = singleRank;
        return false;
      }
    });
    return rank !== null ? rank[get] : 0;
  };

  render() {
    const { selectedOrder, loading } = this.state;

    const orderActionButton = this.state.isSessionStarted ? (
      <div className="screenshot_remove_compare_button_container">
        <RemoveSession resetClickFunc={this.removeSession} />
        <ScreenshotButton screenshotClickFunc={this.saveWorkerScreenshot} />
        <CompareButton
          isOfflineMode={this.state.isOfflineMode}
          screenShots={this.state.screenShots}
          glory={this.props.glory}
          valor={this.props.valor}
          infamy={this.props.infamy}
        />
      </div>
    ) : (
      <StartTrackerButton startClickFunc={this.startTracker} />
    );

    const trackerVisuals = (
      <>
        {selectedOrder.id === 0 ? null : (
          <div className="orderDetail">
            {this._printOrderDetails()}
            <hr />
            {selectedOrder.customerNote ? <CustomerNote note={selectedOrder.customerNote} /> : null}
            {loading ? null : orderActionButton}
            {this.state.isSessionStarted ? (
              <>
                {loading ? null : (
                  <>
                    <hr />
                    <div className="arrayDataInner">
                      <SingleItem />
                    </div>
                    <hr />
                  </>
                )}
                <div className="formContainer">
                  {selectedOrder.stream.toLowerCase() === 'yes' ? (
                    <input
                      type="text"
                      className="twitch_link"
                      placeholder="Twitch Archive Link..."
                      title="Twitch Link"
                      id="worker_twitch_link"
                    />
                  ) : null}
                  <textarea
                    title="Note"
                    className="customTextarea"
                    id="worker_order_note"
                    cols={22}
                    rows={4}
                    placeholder="Enter Note Here..."
                  />
                </div>
                <hr />
                {this?.state?.selectedOrder !== null && this?.state.selectedOrder?.askSuggested ? (
                  <SelectProductButton
                    selectProduct={() => this.setState({ showProductModal: true })}
                    selectedProducts={this.selectedSuggestedProduct}
                  />
                ) : null}
                <hr />
                {loading ? null : <StopOptionsButton />}
              </>
            ) : null}
          </div>
        )}
      </>
    );

    const trackerListOptions = this.state.show ? (
      <div className="arrayDataContainer">
        {loading ? (
          <AjaxLoader />
        ) : this.state.isListLoaded && !this.state.isSessionStarted ? (
          <TrackerList
            change={(event: React.ChangeEvent<HTMLSelectElement>) => {
              this.handleTrackerChange(event);
            }}
            trackerList={this.state.trackerList}
          />
        ) : null}
        {trackerVisuals}
      </div>
    ) : null;

    const canvasImagesConatiner = this.state.isSessionStarted ? (
      <div id="canvas_image_container" style={{ display: 'none' }}>
        <canvas id="glory_Canvas" width="80" height="80" style={{ display: 'none' }} />
        <canvas id="infamy_Canvas" width="80" height="80" style={{ display: 'none' }} />
        <canvas id="valor_Canvas" width="80" height="80" style={{ display: 'none' }} />
        <img src="" id="glory_Image" crossOrigin="anonymous" style={{ display: 'none' }} />
        <img src="" id="infamy_Image" crossOrigin="anonymous" style={{ display: 'none' }} />
        <img src="" id="valor_Image" crossOrigin="anonymous" style={{ display: 'none' }} />
      </div>
    ) : null;

    const trackerWidget = (
      <div className="mainArrayItemDiv">
        {this.state.showTimeModal ? (
          <TimeModal
            onDateModalSelect={(value: string) => {
              this.selectedExtendedOrderDate = value;
              this.setState({ showTimeModal: false });
            }}
          />
        ) : null}
        {this.state.showProductModal ? (
          <ProductModal
            previousSelectedProducts={this.selectedSuggestedProduct}
            ajaxCallInstance={this.ajaxCall.trackerRequest}
            onProductModalSelect={(products: SuggestedProduct[]) => {
              this.selectedSuggestedProduct = products;
              this.setState({ showProductModal: false });
            }}
          />
        ) : null}
        {this.state.offlineView && this.state.isOpenedOffline ? (
          <OfflineListing ajaxCallInstance={this.ajaxCall} />
        ) : null}
        <OptionButton showHide={this.state.show} clickFunc={this.handleOptionToggle} />
        {trackerListOptions}
        {canvasImagesConatiner}
      </div>
    );

    const mainWidget = (
      <TrackerContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          stopButtonClick: (event: React.MouseEvent<HTMLInputElement>) => this.stopTracker(event),
          stopDropDownButtons: this.state.stopButtons,
        }}
      >
        {this.state.userValidated ? trackerWidget : null}
      </TrackerContext.Provider>
    );

    return this.databaseLoadedInitially || this.props.databaseLoaded || this.state.isOpenedOffline
      ? mainWidget
      : null;
  }
}

const mapStateToProps = (state: RootState) => {
  const profilesResponse: DestinyProfileResponse | undefined = state.inventory.profileResponse;
  const characterProgression: null | any[] = profilesResponse
    ? Object.values(profilesResponse?.characterProgressions?.data || {})
    : null;
  const progression: null | DestinyProgression[] = characterProgression?.[0]?.progressions || null;

  // Hash IDs can change in future, if that happens, Just update hash here ahd you can find Hash from wp_v5zh1bp34z_DestinyProgressionDefinition Make sure to update destiny Database on Stage to get latest Data
  const glory: DestinyProgression | number = progression?.[1647151960] || 0;
  const infamy: DestinyProgression | number = progression?.[3008065600] || 0;
  const valor: DestinyProgression | number = progression?.[2083746873] || 0;

  const inventory: DimStore[] = state.inventory.stores;
  const currentItems: NewItem[] = [];
  inventory.forEach((element) => {
    _.forIn(element.items, (singleItem) => {
      const newItem: any = _.pick(singleItem, [
        'id',
        'index',
        'hash',
        'owner',
        'basePower',
        'name',
        'description',
        'icon',
        'type',
        'typeName',
        'tier',
        'destinyVersion',
        'amount',
      ]);
      newItem.owner = _.pick(element, [
        'id',
        'className',
        'genderRace',
        'icon',
        'background',
        'level',
        'powerLevel',
      ]);
      currentItems.push(newItem);
    });
  });

  return {
    databaseLoaded: state.accounts.loaded && state.shell.loadingMessages.length === 0,
    glory,
    infamy,
    valor,
    ranks: state.custom.ranks,
    currentItems: currentItems,
    profileRecords: profilesResponse?.profileRecords?.data?.records,
  };
};

export default connect(mapStateToProps)(AddedDataContainer);
