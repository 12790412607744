import { addCustomData } from 'app/custom-data-handler/actions';
import CompareIcon from 'app/custom-data-handler/components/icons/CompareIcon';
import { triggerRankDispatch } from 'app/custom-data-handler/functions';
import { CustomData } from 'app/custom-data-handler/types';
import { showNotification } from 'app/notifications/notifications';
import store from 'app/store/store';
import axios from 'axios';
import { DestinyProgression } from 'bungie-api-ts/destiny2';
import _ from 'lodash';
import React from 'react';
import './CompareButton.scss';

enum CompareState {
  Start,
  Stop,
  Loading,
}

interface State {
  _compareState: any;
  _itemArrayFull: object[];
}
interface Props {
  screenShots: any[];
  glory: DestinyProgression;
  infamy: DestinyProgression;
  valor: DestinyProgression;
  isOfflineMode: boolean;
}

export default class CompareButton extends React.Component<Props, State> {
  state = {
    _compareState: CompareState.Start,
    _itemArrayFull: [],
  };

  _compareData = async () => {
    if (this.state._compareState === CompareState.Loading) {
      return false;
    }
    if (this.state._compareState === CompareState.Start) {
      let itemJson: any[] = [];
      let compareData: any = document.getElementById('compare_screenshot_data_select_id');
      compareData = compareData.value;
      if (compareData === '') {
        alert('Select Screenshot to Compare');
        return false;
      }
      this.setState({
        _compareState: CompareState.Loading,
      });
      await axios
        .get(`${$SCREENSHOT_AJAX_URL}/GS/?action=screenshot_data&path=${compareData}`)
        .then((response) => {
          itemJson = response.data;
          this.setState({
            _compareState: CompareState.Start,
          });
        })
        .catch(() => {
          this.setState({
            _compareState: CompareState.Start,
          });
        });

      if (!itemJson.length) {
        return false;
      }
      let itemArrayFull: any[] = [];
      itemJson.forEach((element: any) => {
        itemArrayFull = element.items.concat(itemArrayFull);
      });
      this.setState({ _itemArrayFull: itemArrayFull });
      const items: any = document.querySelectorAll('.item');
      items.forEach((element: any) => {
        let title = element.getAttribute('title');
        title = title === null ? '' : title.split('\n')[0];
        let stat: any = element.getElementsByTagName('span')[0];

        if (element.querySelector('.overlay-compare-custom-data-item-compare')) {
          return;
        }

        if (element.id.substr(0, 1) !== '0') {
          if (this._itemFound(this.state._itemArrayFull, element.id)) {
            const child = document.createElement('DIV');
            child.className = 'overlay-compare-custom-data-item-compare';
            element.appendChild(child);
          }
        } else {
          stat = stat === undefined ? 1 : parseInt(stat.innerHTML, 10);
          if (this._quantityMatch(this.state._itemArrayFull, title, stat)) {
            const child = document.createElement('DIV');
            child.className = 'overlay-compare-custom-data-item-compare';
            element.appendChild(child);
          }
        }
      });
    } else {
      const overlays: any = document.querySelectorAll('.overlay-compare-custom-data-item-compare');
      overlays.forEach((element: any) => {
        element.parentNode.removeChild(element);
      });
    }
    this.setState((prevState) => {
      const currentState =
        prevState._compareState === CompareState.Start ? CompareState.Stop : CompareState.Start;
      return {
        _compareState: currentState,
      };
    });
  };

  _itemFound(jsonData: any[], elementId: any) {
    if (!jsonData.length) {
      return false;
    }
    let returnValue = false;
    jsonData.forEach((element: any) => {
      if (element.index === elementId) {
        returnValue = true;
        return false;
      }
    });
    return returnValue;
  }

  _quantityMatch(itemArrayFull: any[], title: string, primaryStat: any) {
    if (isNaN(primaryStat)) {
      return true;
    }

    let returnValue = false;
    itemArrayFull.forEach((element) => {
      if (element.name === title && element.amount === primaryStat) {
        returnValue = true;
        return false;
      }
    });

    return returnValue;
  }

  _getItemByHashId(itemArrayFull: any[], hash: any) {
    const matchedItem = itemArrayFull.filter((element) => element.hash === hash);
    let amount = 0;
    if (matchedItem.length > 0) {
      matchedItem.forEach((element) => {
        amount += element.amount;
      });
    }
    return amount;
  }

  _addDataToState = (e: React.MouseEvent<HTMLButtonElement> | undefined) => {
    e?.preventDefault();
    const weaponAndArmor = [
      'Kinetic',
      'Energy',
      'Power',
      'Helmet',
      'Gauntlets',
      'Chest',
      'Leg',
      'ClassItem',
    ];
    if (this.state._compareState !== CompareState.Stop) {
      alert('First Compare Screenshot then you can add Items');
      return false;
    }
    const items: any[] = this.getAllItemData();
    items.forEach((item) => {
      let stat = 0;
      if (item.index.substr(0, 1) !== '0') {
        if (!this._itemFound(this.state._itemArrayFull, item.index)) {
          if (item.tier === 'Rare' && weaponAndArmor.includes(item.type)) {
            return false;
          }
          switch (item.type) {
            case 'Ships':
            case 'Ghost':
            case 'Class':
              stat = item.amount;
              break;
            case 'Consumables':
            case 'Shaders':
              stat = item.amount - this._getItemByHashId(this.state._itemArrayFull, item.hash);
              break;
            default:
              stat = 0;
          }
          if (stat < 0) {
            return false;
          }
          const data: CustomData = {
            id: item.index,
            hashId: item.hash,
            instanceId: item.id,
            name: item.name,
            description: item.description,
            hasIcon: true,
            icon:
              'https://bungie.net' + item.icon ||
              'https://guardian.services/wp-content/uploads/2019/08/cursor.gif',
            type: item.type === 'Emblems' ? 'Emblem' : item.type,
            typeName: item.typeName,
            rarity: item.tier || 'Basic',
            stats: item.basePower || stat,
          };
          store.dispatch(addCustomData({ item: data, isBulk: true }));
        }
      } else {
        if (!this._quantityMatch(this.state._itemArrayFull, item.name, item.amount)) {
          if (item.tier === 'Rare' && weaponAndArmor.includes(item.type)) {
            return false;
          }
          switch (item.type) {
            case 'Ships':
            case 'Ghost':
            case 'Emblems':
            case 'Class':
              stat = item.amount;
              break;
            case 'Consumables':
            case 'Shaders':
              stat = item.amount - this._getItemByHashId(this.state._itemArrayFull, item.hash);
              break;
            default:
              stat = 0;
          }
          if (stat < 0) {
            return false;
          }
          const data = {
            id: item.index,
            hashId: item.hash,
            instanceId: item.id,
            name: item.name,
            description: item.description,
            hasIcon: true,
            icon:
              'https://bungie.net' + item.icon ||
              'https://guardian.services/wp-content/uploads/2019/08/cursor.gif',
            type: item.type,
            typeName: item.typeName,
            rarity: item.tier || 'Basic',
            stats: item.basePower || stat,
          };
          store.dispatch(addCustomData({ item: data, isBulk: true }));
        }
      }
    });
    showNotification({
      type: 'success',
      title: 'Item(s) Added',
      body: 'Action Completed Successfully',
      duration: 2000,
    });
    triggerRankDispatch(this.props, this.props.isOfflineMode);
  };

  getAllItemData() {
    const stores = store.getState().inventory.stores;
    const fixedStores: any[] = [];
    stores.forEach((storeObject) => {
      _.forIn(storeObject.items, (singleStore) => {
        const newItem: any = _.pick(singleStore, [
          'id',
          'index',
          'hash',
          'owner',
          'basePower',
          'name',
          'description',
          'icon',
          'type',
          'typeName',
          'tier',
          'destinyVersion',
          'amount',
        ]);
        fixedStores.push(newItem);
      });
    });
    return fixedStores;
  }

  render() {
    const screenshotsArray = this.props.screenShots;

    return (
      <div className="takeCompareButtonDiv" title="Compare Data">
        <button
          type="button"
          className="takeCompareButton"
          onClick={this._compareData}
          onContextMenu={this._addDataToState}
        >
          <span className="icon">
            <CompareIcon />
          </span>
          {this.state._compareState === CompareState.Start ? (
            <span className="text">Compare Items</span>
          ) : this.state._compareState === CompareState.Loading ? (
            <span className="text">Loading...</span>
          ) : (
            <span className="text" title="Right Click to Add new Items">
              Stop Compare
            </span>
          )}
        </button>
        <select className="compareSelect" id="compare_screenshot_data_select_id">
          {screenshotsArray.map((element: any, index) => (
            <option key={index} value={element.path}>
              {index + 1}). {element.title}
            </option>
          ))}
        </select>
      </div>
    );
  }
}
