import { showNotification } from 'app/notifications/notifications';
import store from 'app/store/store';
import {
  DestinyObjectiveProgress,
  DestinyRecordComponent,
  DestinyRecordDefinition,
} from 'bungie-api-ts/destiny2/interfaces';
import _ from 'lodash';
import { addRankData, removeRankData } from './actions';
import { imageData } from './data/images';
import { glory, infamy, maxProgress, valor } from './data/info';
import {
  CustomObjective,
  CustomTriumph,
  NotificationText,
  SingleRankData,
  triggerRankDispatchProps,
} from './types';
export function getRankInfo(progress: number, type: string) {
  let Rank: SingleRankData | null = null;
  let i = 0;
  switch (type) {
    case 'glory':
      for (i = 0; i < glory.ranks.length; i++) {
        if (progress <= glory.ranks[i].to) {
          Rank = { ...glory.ranks[i] };
          break;
        }
      }
      break;
    case 'valor':
      for (i = 0; i < valor.ranks.length; i++) {
        if (progress <= valor.ranks[i].to) {
          Rank = { ...valor.ranks[i] };
          break;
        }
      }
      break;
    case 'infamy':
      for (i = 0; i < infamy.ranks.length; i++) {
        if (progress <= infamy.ranks[i].to) {
          Rank = { ...infamy.ranks[i] };
          break;
        }
      }
      break;

    default:
      Rank = null;
      break;
  }
  return Rank !== null
    ? Rank
    : {
        name: '',
        level: '',
        from: 0,
        to: 0,
        pointsToNextRank: 0,
      };
}

export function calculateRankAngle(oldRank: number, newRank: number, type: string) {
  const oldRankData: SingleRankData = getRankInfo(oldRank, type);
  const newRankData: SingleRankData = getRankInfo(newRank, type);

  if (oldRankData.name === '' || newRankData.name === '') {
    return {
      outerBefore: -90,
      outerAfter: -90,
      innerBefore: -90,
      innerAfter: -90,
      level: '',
    };
  }
  const outerBefore: number = getAngle(oldRank, maxProgress[type]);
  const outerAfter: number = getAngle(newRank, maxProgress[type]);
  const innerBefore: number =
    oldRankData.to !== newRankData.to
      ? -90
      : getAngle(oldRank - oldRankData.from, newRankData.to - oldRankData.from);
  const innerAfter: number = getAngle(
    newRank - newRankData.from,
    newRankData.to - oldRankData.from
  );
  return {
    outerBefore,
    outerAfter,
    innerBefore,
    innerAfter,
    level: newRankData.level,
  };
}

export function getAngle(stat: number, max: number) {
  return 360 / (max / stat) - 90;
}

export function triggerRankDispatch(props: triggerRankDispatchProps, isOffline: boolean) {
  let sessionData: any = window.localStorage.getItem('sessionData');
  sessionData = JSON.parse(sessionData);
  const { glory, infamy, valor } = sessionData.ranks;

  // Removing Ranks
  store.dispatch(removeRankData());

  // Calculating Glory
  store.dispatch(
    addRankData({
      id: isOffline ? 0 : props.glory.progressionHash,
      rankType: 'Glory',
      slug: 'glory',
      oldProgress: isNaN(Number(glory)) ? glory.currentProgress : 0,
      newProgress: isOffline ? 0 : props.glory.currentProgress,
    })
  );

  // Calculating Infamy
  store.dispatch(
    addRankData({
      id: isOffline ? 1 : props.infamy.progressionHash,
      rankType: 'Infamy',
      slug: 'infamy',
      oldProgress: isNaN(Number(infamy)) ? infamy.currentProgress : 0,
      newProgress: isOffline ? 0 : props.infamy.currentProgress,
    })
  );

  // Calculating Valor
  store.dispatch(
    addRankData({
      id: isOffline ? 2 : props.valor.progressionHash,
      rankType: 'Valor',
      slug: 'valor',
      oldProgress: isNaN(Number(valor)) ? valor.currentProgress : 0,
      newProgress: isOffline ? 0 : props.valor.currentProgress,
    })
  );
}

export async function getUpdatedTriumph(
  afterRecords: { [key: number]: DestinyRecordComponent },
  beforeRecords: { [key: number]: DestinyRecordComponent }
) {
  const getRecords = store.getState().manifest.d2Manifest?.Record.get;
  const getObjectives = store.getState().manifest.d2Manifest?.Objective.get;

  if (!getRecords || !getObjectives) {
    return [];
  }

  const validTriumphs: CustomTriumph[] = [];

  _.forIn(afterRecords, function (after: DestinyRecordComponent, hashId) {
    if (after.state & 16) {
      return;
    }

    if (beforeRecords?.[hashId]) {
      const before: DestinyRecordComponent = beforeRecords?.[hashId];
      const intervalType: string = after?.intervalObjectives
        ? 'interval'
        : after?.objectives
        ? 'objective'
        : 'none';
      if (checkIfAnythingChanges(before, after)) {
        const objective: CustomObjective[] = [];
        (after?.intervalObjectives || after?.objectives || []).forEach((item) => {
          const singleObjective: CustomObjective = {
            ...item,
            beforeProgress:
              intervalType === 'interval'
                ? before.intervalObjectives.find((e) => e.objectiveHash === item.objectiveHash)
                    ?.progress || 0
                : intervalType === 'objective'
                ? before.objectives.find((e) => e.objectiveHash === item.objectiveHash)?.progress ||
                  0
                : 0,
            displayProperties: getObjectives(Number(item.objectiveHash)).progressDescription,
          };

          objective.push(singleObjective);
        });
        const currentRecordDefinition: DestinyRecordDefinition = getRecords(Number(hashId));
        let currentScore = 0;
        let totalScore = 0;
        if (intervalType === 'objective') {
          totalScore = currentRecordDefinition.completionInfo.ScoreValue;
        } else if (intervalType === 'interval') {
          currentScore = _.sumBy(
            _.take(
              currentRecordDefinition.intervalInfo.intervalObjectives,
              after.intervalsRedeemedCount
            ),
            (i) => i.intervalScoreValue
          );
          totalScore = _.sumBy(
            currentRecordDefinition.intervalInfo.intervalObjectives,
            (i) => i.intervalScoreValue
          );
        }

        const triumphObject: CustomTriumph = {
          state: after.state,
          points: {
            currentScore,
            totalScore,
          },
          intervalsRedeemedCount: after.intervalsRedeemedCount,
          haveValidObjectives: intervalType !== 'none',
          objectiveType: intervalType,
          hashId: Number(hashId),
          displayProperties: {
            ...currentRecordDefinition.displayProperties,
            icon: currentRecordDefinition?.displayProperties?.icon
              ? currentRecordDefinition?.displayProperties?.icon.includes('bungie.net')
                ? currentRecordDefinition.displayProperties.icon
                : 'https://bungie.net' + currentRecordDefinition.displayProperties.icon
              : '',
          },
          objective: objective,
        };

        validTriumphs.push(triumphObject);
      }
    }
  });

  return validTriumphs;

  function checkIfAnythingChanges(
    original: DestinyRecordComponent,
    updated: DestinyRecordComponent
  ) {
    let changed = false;
    (original?.intervalObjectives || original?.objectives || []).forEach((item) => {
      const uItem: DestinyObjectiveProgress | undefined = (
        updated?.intervalObjectives ||
        updated?.objectives ||
        []
      ).find((singleObjective) => singleObjective.objectiveHash === item.objectiveHash);
      if (uItem === undefined) {
        return;
      }
      if (
        uItem.progress !== undefined &&
        item.progress !== undefined &&
        uItem.progress > item.progress
      ) {
        changed = true;
        return false;
      }
    });
    return changed;
  }
}

export const formatExtendedOrderDateTime = (extendedOrderDateTime: Date) => ({
  timestamp: ~~(extendedOrderDateTime.getTime() / 1000),
  timezoneOffset: extendedOrderDateTime.getTimezoneOffset(),
  timestampUTC: ~~(
    new Date(
      `${extendedOrderDateTime.getUTCFullYear()}-${(
        '0' +
        (extendedOrderDateTime.getUTCMonth() + 1)
      ).slice(-2)}-${('0' + extendedOrderDateTime.getUTCDate()).slice(-2)} ${(
        '0' + extendedOrderDateTime.getUTCHours()
      ).slice(-2)}:${('0' + extendedOrderDateTime.getUTCMinutes()).slice(-2)}:${(
        '0' + extendedOrderDateTime.getUTCSeconds()
      ).slice(-2)}`
    ).getTime() / 1000
  ),
});

export const notify = (notificationData: NotificationText) => {
  showNotification({
    type: notificationData.type,
    title: notificationData.title,
    body: notificationData.message,
    duration: 5000,
  });
};

export const getSesstionData = () => {
  let workerId: any = window.sessionStorage.getItem('userId');
  workerId = workerId ? parseInt(workerId, 10) : 0;
  let token: any = window.sessionStorage.getItem('token');
  token = token || '0';
  let onlyOffline: any = window.sessionStorage.getItem('onlyOffline');
  onlyOffline = parseInt(onlyOffline, 10) || 0;
  let expire: any = window.sessionStorage.getItem('expire');
  expire = expire ? parseInt(expire, 10) : 0;
  return {
    workerId,
    token,
    onlyOffline,
    expire,
  };
};

const drawCanvasLines = async (
  context: CanvasRenderingContext2D,
  outerBefore: number,
  outerAfter: number,
  innerBefore: number,
  innerAfter: number,
  colorAfter: string,
  colorBefore: string
) => {
  context.beginPath();
  context.arc(40, 40, 32, (Math.PI / 180) * -90, (Math.PI / 180) * 360);
  context.strokeStyle = '#555555';
  context.lineWidth = 5;
  context.stroke();

  context.beginPath();
  context.arc(40, 40, 32, (Math.PI / 180) * -90, (Math.PI / 180) * innerAfter);
  context.strokeStyle = colorAfter;
  context.lineWidth = 5;
  context.stroke();

  context.beginPath();
  context.arc(40, 40, 32, (Math.PI / 180) * -90, (Math.PI / 180) * innerBefore);
  context.strokeStyle = colorBefore;
  context.lineWidth = 5;
  context.stroke();

  context.beginPath();
  context.arc(40, 40, 36, (Math.PI / 180) * -90, (Math.PI / 180) * 360);
  context.strokeStyle = '#555555';
  context.lineWidth = 5;
  context.stroke();

  context.beginPath();
  context.arc(40, 40, 36, (Math.PI / 180) * -90, (Math.PI / 180) * outerAfter);
  context.strokeStyle = colorAfter;
  context.lineWidth = 5;
  context.stroke();

  context.beginPath();
  context.arc(40, 40, 36, (Math.PI / 180) * -90, (Math.PI / 180) * outerBefore);
  context.strokeStyle = '#68A0B7';
  context.lineWidth = 5;
  context.stroke();
};

const addImageSrc = (image: HTMLImageElement, imageName: string) =>
  new Promise((resolve, reject) => {
    image.onload = resolve;
    image.onerror = reject;
    image.src = imageName;
  });

export const getRankImageDataUrl = async (type: string, oldValue: number, newValue: number) => {
  if (oldValue === newValue) {
    return false;
  }
  const canvas = document.getElementById(`${type}_Canvas`) as HTMLCanvasElement;
  const image = document.getElementById(`${type}_Image`) as HTMLImageElement;
  const context = canvas.getContext('2d');

  if (!context) {
    return false;
  }

  const { outerBefore, outerAfter, innerBefore, innerAfter, level } = calculateRankAngle(
    oldValue,
    newValue,
    type
  );
  if (!level) {
    return false;
  }

  const imageName = imageData[type][level];
  const imageLoaded = await addImageSrc(image, imageName);
  if (!imageLoaded) {
    return false;
  }
  context.fillStyle = type === 'infamy' ? '#1D4338' : '#4F221F';
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.drawImage(image, 10, 10, 60, 60);
  const colorAfter = '#ff6800';
  const colorBefore = type === 'infamy' ? '#41C0A0' : type === 'glory' ? '#C51713' : '#E9A13F';
  drawCanvasLines(
    context,
    outerBefore,
    outerAfter,
    innerBefore,
    innerAfter,
    colorAfter,
    colorBefore
  );
  return canvas.toDataURL('image/png', 1.0);
};
