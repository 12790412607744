import { makeCustomIcon } from './utils';

export const dimTitanIcon = makeCustomIcon(
  'Titan',
  32,
  32,
  'm14.839 15.979-13.178-7.609v15.218zm2.322 0 13.178 7.609v-15.218zm5.485-12.175-6.589-3.804-13.178 7.609 13.178 7.609 13.179-7.609zm0 16.784-6.589-3.805-13.178 7.609 13.178 7.608 13.179-7.608-6.59-3.805z'
);

export default dimTitanIcon;
