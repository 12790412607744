import { addCustomData } from 'app/custom-data-handler/actions';
import { CustomData } from 'app/custom-data-handler/types';
import store from 'app/store/store';
import { DestinyInventoryItemDefinition } from 'bungie-api-ts/destiny2/interfaces';
import React from 'react';
import './OfflineItem.scss';

interface Props {
  item: DestinyInventoryItemDefinition;
}

function OfflineItem(props: Props) {
  function _handleRightClick(e: any, itemData: CustomData) {
    e.preventDefault();

    // const itemData = JSON.parse(e.currentTarget.dataset.item);
    const stat = prompt('Enter Power/Quantity');
    if (stat === null) {
      alert('Cannot Add this item without Stat');
      return false;
    }
    const statValue = parseInt(stat, 10);
    if (isNaN(statValue)) {
      alert('Stat Can only be Integer Value');
      return false;
    }
    itemData.stats = statValue;
    store.dispatch(addCustomData({ item: itemData, isBulk: false }));
  }

  const getRarityTypeFromProperty = (property: string) => {
    if (property.startsWith('Exotic')) {
      return 'Exotic';
    } else if (property.startsWith('Legendary')) {
      return 'Legendary';
    } else if (property.startsWith('Rare')) {
      return 'Rare';
    } else if (property.startsWith('Uncommon')) {
      return 'Uncommon';
    } else if (property.startsWith('Common')) {
      return 'Common';
    } else if (property.startsWith('Mission')) {
      return 'Mission';
    } else {
      return undefined;
    }
  };

  const item = props.item;
  let stat = 0;
  if (item.displayProperties.name === 'Classified' || item.displayProperties.name === '') {
    return null;
  }
  switch (item.itemTypeDisplayName) {
    case 'Ships':
    case 'Ghost':
    case 'Emblems':
    case 'Class':
      stat = 1;
      break;
    default:
      stat = 0;
  }

  const rarity =
    item?.inventory?.tierTypeName ||
    getRarityTypeFromProperty(item.itemTypeAndTierDisplayName) ||
    'Basic';

  const data: CustomData = {
    id: item.hash.toString(),
    hashId: item.hash,
    instanceId: '',
    name: item.displayProperties.name,
    description: item.displayProperties.description,
    hasIcon: item.displayProperties.hasIcon,
    icon: item.displayProperties.hasIcon
      ? 'https://bungie.net' + item.displayProperties.icon
      : 'https://guardian.services/wp-content/uploads/2019/08/cursor.gif',
    type: item.itemTypeDisplayName,
    typeName: item.itemTypeAndTierDisplayName,
    rarity: rarity,
    stats: stat,
  };

  return (
    <div
      className={`single_item ${rarity}`}
      onContextMenu={(e: React.MouseEvent<HTMLDivElement> | undefined) =>
        _handleRightClick(e, data)
      }
    >
      {item.displayProperties.hasIcon ? (
        <div
          className="image_container"
          style={{ backgroundImage: `url(https://bungie.net${item.displayProperties.icon})` }}
        ></div>
      ) : null}
      <div className="information">
        <table>
          <tbody>
            <tr>
              <td>
                <strong>{item.displayProperties.name}</strong>
              </td>
            </tr>
            {item.itemTypeDisplayName ? (
              <tr>
                <td>Type: {item.itemTypeDisplayName}</td>
              </tr>
            ) : null}
          </tbody>
        </table>
        {/* <span className="property">
          <span className="property-title"> Name: </span>
          <span className="property-value">{item.displayProperties.name}</span>
        </span>
        <span className="property">
          <span className="property-title"> Type: </span>
          <span className="property-value">{item.itemTypeDisplayName}</span>
        </span> */}
      </div>
    </div>
  );
}

export default OfflineItem;
