import { notify } from 'app/custom-data-handler/functions';
import { RootState } from 'app/store/types';
import { Reducer } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { CustomData, RankData } from './types';

export interface CustomState {
  added: CustomData[];
  ranks: RankData[];
}

export type CustomAction = ActionType<typeof actions>;
export const addedDataSelector = (state: RootState) => state.custom.added;

const saveToken = () => {
  let href = window.location.href;
  if (href.includes('&')) {
    const tempHref = href.split('&');
    href = tempHref[0];
    if (tempHref.length === 2 && tempHref[1] === 'load_offline=1') {
      window.sessionStorage.setItem('onlyOffline', '1');
    }
  }
  const hrefSplit = href.split('_-_');
  if (hrefSplit.length !== 2) {
    return false;
  }
  const tokenString = hrefSplit[1];
  const tokenSplit = tokenString.split('___');
  if (tokenSplit.length !== 3) {
    return false;
  }
  const tokenStringSplit = tokenSplit[0].split('=');
  if (tokenStringSplit.length !== 2) {
    return false;
  }
  const token = tokenStringSplit[1];
  const userId = tokenSplit[1];
  const expire = tokenSplit[2];
  window.sessionStorage.setItem('token', token);
  window.sessionStorage.setItem('userId', userId);

  window.sessionStorage.setItem(
    'expire',
    (new Date().getTime() + parseInt(expire, 10) * 1000).toFixed()
  );
};
saveToken();

const initialState: CustomState = {
  added: [],
  ranks: [],
};

export const custom: Reducer<CustomState, CustomAction> = (
  state: CustomState = initialState,
  action: CustomAction
) => {
  switch (action.type) {
    case getType(actions.addCustomData): {
      const body = `Name: ${action.payload.item.name}, Type: ${action.payload.item.type}`;
      if (checkIfAvailableInAdded(state.added, action.payload.item)) {
        if (!action.payload.isBulk) {
          notify({
            type: 'error',
            title: 'Item Already Added',
            message: body,
          });
        }
        return state;
      }
      if (!action.payload.isBulk) {
        notify({
          type: 'success',
          title: 'Item Added',
          message: body,
        });
      }
      return {
        ...state,
        added: [...state.added, action.payload.item],
      };
    }

    case getType(actions.addRankData): {
      if (checkIfAvailableInAdded(state.ranks, action.payload)) {
        return state;
      }

      return {
        ...state,
        ranks: [...state.ranks, action.payload],
      };
    }

    case getType(actions.removeRankData): {
      return {
        ...state,
        ranks: [],
      };
    }

    case getType(actions.updateRankData): {
      const tempRankState = [...state.ranks];
      const tempRank = { ...tempRankState[action.payload.index] };
      tempRank[action.payload.type] = action.payload.value;
      tempRankState[action.payload.index] = tempRank;
      return {
        ...state,
        ranks: [...tempRankState],
      };
    }

    case getType(actions.removeCustomData): {
      delete state.added[action.payload];
      const filteredArray = state.added.filter((el) => el !== undefined);
      notify({
        type: 'success',
        title: 'Action Performed',
        message: 'Item Removed',
      });
      return {
        ...state,
        added: filteredArray,
      };
    }

    case getType(actions.editQuantity): {
      if (state !== undefined) {
        state.added[action.payload.id].stats = action.payload.value;
      }
      return {
        ...state,
        added: [...state.added],
      };
    }

    case getType(actions.removeAllData):
      return { ...state, added: [] };

    default:
      return state;
  }
};

function checkIfAvailableInAdded(added: CustomData[] | RankData[], payload: CustomData | RankData) {
  let ifExists = false;
  added.forEach((singleAdded: CustomData | RankData) => {
    if (singleAdded.id === payload.id) {
      ifExists = true;
    }
  });
  return ifExists;
}
